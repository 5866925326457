<template>
  <div
    id="properties-slider-container"
    :class="{
      hidden: items?.length === 0
    }"
  >
    <DefinePropertyCard
      v-slot="{
        image,
        imageSet,
        imageAlt,
        logoImage,
        logoImageSet,
        logoImageAlt,
        to
      }"
    >
      <NuxtLink
        class="group flex hover:opacity-90 flex-col w-[218px] h-[386px] gap-3 transition-opacity"
        :to="to"
      >
        <div
          class="w-full h-[295px] rounded-lg overflow-hidden shadow-lg group-hover:shadow-xl transition-shadow"
        >
          <img
            v-if="image"
            :src="image"
            :srcset="imageSet"
            loading="lazy"
            :alt="imageAlt"
            class="w-full h-full object-cover object-center group-hover:scale-[1.025] transition-transform"
            width="218"
            height="295"
          />
        </div>
        <div class="relative w-full flex-1 text-center">
          <img
            v-if="logoImage"
            :src="logoImage"
            :srcset="logoImageSet"
            loading="lazy"
            :alt="logoImageAlt"
            class="absolute w-full h-full left-0 top-0 object-contain mx-auto"
            width="218"
            height="91"
          />
          <span v-else class="mt-6 text-lg font-bold text-blue-dark">
            {{ logoImageAlt }}
          </span>
        </div>
      </NuxtLink>
    </DefinePropertyCard>
    <Splide
      :options="{
        focus: false,
        perMove: 3,
        drag: 'free',
        fixedWidth: '218px',
        gap: '16px',
        pagination: false,
        trimSpace: 'move',
        omitEnd: true,
        i18n: {
          slide: ''
        },
        breakpoints: {
          640: {
            arrows: false
          }
        }
      }"
      class="splide-center mb-6"
    >
      <SplideSlide v-for="(item, index) in items" :key="index">
        <PropertyCard v-bind="item"></PropertyCard>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script setup lang="ts">
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
import { createReusableTemplate } from '@vueuse/core';

import { fixImageUrl } from '@/helpers/fixes';
import type {
  ProjectDocumentResponse,
  QueryResponse
} from '@/types/properties';

type Property = {
  image: string;
  imageSet: string;
  imageAlt: string;
  logoImage: string;
  logoImageSet: string;
  logoImageAlt: string;
  to: string;
};

const props = defineProps({
  type: {
    type: String as PropType<'apartments' | 'houses'>,
    required: true
  }
});

const [DefinePropertyCard, PropertyCard] = createReusableTemplate<Property>();

const {
  public: { cloudflareImageProcessing: cfUrl }
} = useRuntimeConfig();

const { createSearchParams } = useSearch();

const { getProjectLink } = useProperties();

const { t } = useI18n();

const {
  public: { apiUrl }
} = useRuntimeConfig();

const { get } = useHttpMethods();

const localePath = useLocalePath();

async function fetch() {
  const queryParams = createSearchParams({
    collection: 'projects',
    perPage: 15,
    sortBy: 'order:asc',
    filterBy: ['projectStatus:!=on-the-market', 'featured:true'],
    propertyType: props.type === 'apartments' ? 'apartment' : 'house'
  });

  const url =
    `${apiUrl}/search/projects/?` +
    Object.entries(queryParams)
      .map(vs => new URLSearchParams({ [vs[0]]: vs[1] }))
      .join('&');

  const response = await get<{ data: QueryResponse<ProjectDocumentResponse> }>(
    url
  );

  if (!response.data) {
    throw new Error('No data');
  }

  return response.data.hits.map(hit => {
    const link = getProjectLink(hit.document, t);
    return {
      image: hit.document.photos[0],
      imageAlt: t('common.photoOf', [hit.document.projectName]),
      logoImage: hit.document.logo,
      logoImageAlt: hit.document.projectName,
      to: localePath(`/${t('routes.projects')}/${link}`)
    };
  });
}

const { data: asyncItems, error } = await useAsyncData<Property[]>(
  `portfolio-${props.type}`,
  async () => {
    if (process.client) {
      return [] as Property[];
    }

    return (await fetch()) as Property[];
  }
);

const clientItems = ref<Property[]>([]);

onMounted(() => {
  if (!asyncItems.value?.length) {
    fetch().then(response => (clientItems.value = response));
  }
});

const items = computed(() => {
  const items = asyncItems.value?.length ? asyncItems.value : clientItems.value;

  return items.map(item => {
    const fixedImage = item.image ? fixImageUrl(item.image) : '';
    const image = fixedImage
      ? `${cfUrl}/image?w=218&h=295&f=webp&fit=crop&image=${fixedImage}`
      : '';
    const imageSet = fixedImage ? `${image} 1x` : '';
    const fixedLogoImage = item.logoImage ? fixImageUrl(item.logoImage) : '';
    const logoImage = fixedLogoImage
      ? `${cfUrl}/image?w=218&h=91&f=webp&fit=scale-down&image=${fixedLogoImage}`
      : '';
    const logoImageSet = fixedLogoImage
      ? `${logoImage} 1x, ${cfUrl}/image?w=436&h=182&f=webp&fit=scale-down&image=${fixedLogoImage} 2x`
      : '';
    return {
      ...item,
      image,
      imageSet,
      logoImage,
      logoImageSet
    };
  });
});

if (error.value) {
  console.error(error.value);
}
</script>

<style lang="scss">
@import '~/assets/css/splide-theme.scss';
</style>

<style scoped lang="scss">
#properties-slider-container :deep(.splide__arrow) {
  top: calc(50% - 38px);
}
</style>
